import massagistsAPI from "@/api/massagist.js";

export default async function(massagists){
    let massagistsIDs = massagists.map(massagist => massagist.id);

    let bets = await massagistsAPI.getBetsByIDs({ massagists_ids: massagistsIDs })
        .then(response => response.data)
        .catch(err => console.log(err))
    let photos = await massagistsAPI.getPhotosForMultipleMassagists({ massagists_ids: massagistsIDs })
        .then(response => response.data)
        .catch(err => console.log(err))
    
    let balances = await massagistsAPI.getBalancesByIDs({ massagists_ids: massagistsIDs })
        .then(response => response.data)
        .catch(err => console.log(err))

    
    massagists.forEach(massagist => {
        bets.forEach(bet => {
            if(bet.massagist_id === massagist.id){
                massagist.bet = bet;
            }
        })
        let massagistPhotos = []
        photos.forEach(photo => {
            if(photo.massagist_id === massagist.id){
                massagistPhotos.push(photo)
            }
        })
        massagist.photos = massagistPhotos;

        balances.forEach(balance => {
            if(balance.massagist_id === massagist.id){
                massagist.balance = balance;
            }
        })

        massagist.inFavorite = 0;
    })


    return massagists;
}