import massagistsAPI from "@/api/massagist.js";
import setMassagistsBetsBalancesAndPhotos from "./setMassagistsBetsBalancesAndPhotos";



export default async function(city){
    let massagists = await massagistsAPI.getAllMassagistsByCity({ city: city })
        .then(response => response.data)
        .catch(err => console.log(err))

    // massagists = await setMassagistsBetsBalancesAndPhotos(massagists);
    
    return massagists;    
    
} 