import saloonsAPI from "../api/saloon.js";
import massagistsAPI from "../api/massagist.js";
import setMassagistsBetsBalancesAndPhotos from "./setMassagistsBetsBalancesAndPhotos.js";

export default {
    async searchMassagistByOptions(options){
        if(options.sex === 'saloon'){
            const data = await saloonsAPI.getSaloons({ city: options.city})
                .then(response => response.data)
                .catch(err => console.log(err))

                return data;

        } else {
            let massagists = await massagistsAPI.findMassagistsBySearchingOptions({
                sex: options.sex,
                client: options.client,
                place: options.place,
                city: options.city
            }).then(response => response.data).catch(err => console.log(err))

            if(massagists.error){
                return massagists;
            } else {
                massagists = await setMassagistsBetsBalancesAndPhotos(massagists);
    
                return massagists;
            }

        }
    }
}