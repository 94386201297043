export default {    
        selects: {
            massagist: {
                name: 'massagist',
                options: [
                    {
                        value: 'who',
                        holder: 'Кого я ищу',
                        selected: true,
                        disabled: true
                    },
                    {
                        value: 'male',
                        holder: 'Мужчина',
                        selected: false,
                        disabled: false
                    },
                    {
                        value: 'female',
                        holder: 'Женщина',
                        selected: false,
                        disabled: false
                    },
                    {
                        value: 'saloon',
                        holder: 'Салон',
                        selected: false,
                        disabled: false
                    },
                ]
            },
            client: {
                name: 'client',
                options: [
                    {
                        value: 'who',
                        holder: 'Кому массаж',
                        selected: true,
                        disabled: true
                    },
                    {
                        value: 'Men',
                        holder: 'Мужчине',
                        selected: false,
                        disabled: false
                    },
                    {
                        value: 'Women',
                        holder: 'Женщине',
                        selected: false,
                        disabled: false
                    },
                    {
                        value: 'Couples',
                        holder: 'Паре',
                        selected: false,
                        disabled: false
                    },
                ]
            },
            place: {
                name: 'place',
                options: [
                    {
                        value: 'place',
                        holder: 'Где массаж',
                        selected: true,
                        disabled: true
                    },
                    {
                        value: 'home',
                        holder: 'На дому',
                        selected: false,
                        disabled: false
                    },
                    {
                        value: 'master',
                        holder: 'У мастера',
                        selected: false,
                        disabled: false
                    },
                ]
            },
            cities: {
                name: 'city',
                options: [
                    {
                        value: 'city',
                        holder: 'Выберите город',
                        selected: true,
                        disabled: true
                    },
                    {
                        value: 'moscow',
                        holder: 'Москва',
                        selected: false,
                        disabled: false
                    },
                    {
                        value: 'saint-peterburg',
                        holder: 'Санкт-Петербург',
                        selected: false,
                        disabled: false
                    },
                    {
                        value: 'ekb',
                        holder: 'Екатеринбург',
                        selected: false,
                        disabled: false
                    },
                ]
            },

        }
}