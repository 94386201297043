<template>
    <div class="app-massagist-main-card">
        <div class="line" :style="`left: ${left}%`"></div>
    </div>
</template>

<script>

    export default {
        data(){
            return {
                left: 110
            }
        },
        methods: {
            moveLine(){
                setInterval(() => {
                    this.left++;
                    if(this.left > 110){
                    this.left = -40;
                    }
                }, 5)
            }
        },
        mounted(){
            this.moveLine();
        }
    }
</script>

<style lang="scss" scoped>
    .app-massagist-main-card{
        width: 20%;
        min-width: 340px;
        height: 500px;
        background-color: $accent;
        border: transparent;
        border-radius: 0.5em;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 2em 1em;
        overflow: hidden;
        .line{
            width: 1px;
            height: 600px;
            background-color: rgba(225, 231, 236, 0.2);
            position: absolute;
            top: -20%;
            left: 20%;
            box-shadow: 4px 4px 41px 25px rgba(231, 236, 240, 0.2);
            transform: rotate(25deg);
        }
    }
</style>