<template>
    <div class="app-massagist-main-card">
        <div class="card-gallery" @click="$emit('action')">
            <div class="main-img">
                <img :src="massagistPhotoPath + massagist.id + '/' + massagist.photos[mainPhoto].link" alt="" v-if="massagist.photos.length > 0 && massagist.photos[mainPhoto].approved">
                <h2 v-else class="warning">Фото не добавлены либо проходят проверку модератором</h2>
            </div>
            <div class="sub-gallary">
                <div class="sub-img" v-for="(photo, index) in massagist.photos" :key="index" @mouseover="changePhoto(index)">
                    <img :src="massagistPhotoPath + massagist.id + '/' + photo.link" alt="" v-if="massagist.photos[mainPhoto].approved">
                </div>
            </div>
        </div>
        <div class="card-options">
            <!-- <AppCheckIcon class="icon" v-if="massagist.verified.is_verified" /> -->
            <!-- <AppVipIcon class="icon" v-if="massagist.isVip" /> -->
            <!-- <AppRatingIcon class="icon" :rating="massagist.rating" v-if="massagist.rating" /> -->
        </div>
        <div class="card-description">
            <span class="name">{{ massagist.username}}<span class="age" v-if="massagist.age">{{", " + massagist.age + " лет" }}</span></span>
            <span class="location" v-if="massagist.location">{{ massagist.location }}</span>
            <span class="price" v-if="massagist.my_place_per_hour_price">от {{ massagist.my_place_per_hour_price + " руб/час" }}</span>
            <AppHeartBtn @like="$emit('like', massagist.id)" :options="isInFavorite" class="description-like"/>
            <span class="more" @click="$emit('action')">Подробнее...</span>
        </div>
    </div>
</template>

<script>
import AppCheckIcon from "@/components/icons/app-check-icon.vue";
import AppVipIcon from "@/components/icons/app-vip-icon.vue";
import AppRatingIcon from "@/components/icons/app-rating-icon.vue";
import AppHeartBtn from "@/components/buttons/app-heart-btn.vue";
import domain from "@/api/domain.js";

    export default {
        components: {
            AppCheckIcon,
            AppVipIcon,
            AppRatingIcon,
            AppHeartBtn
        },
        props: {
            massagist: {
                type: Object,
                default: {}
            }
        },
        data(){
            return {
                mainPhoto: 0,
                massagistPhotoPath: domain.massagistPhotosPath
            }
        },
        methods: {
            like(){
                this.heartBtn.light = !this.heartBtn.light;
                this.heartBtn.dark = !this.heartBtn.dark;
            },
            changePhoto(index){
                this.mainPhoto = index;
            },
            getMainPhoto(){
                this.massagist.photos.forEach((photo, index) => {
                     if(photo.main_photo === 1){
                        this.mainPhoto = index;
                    }
                })
            }
        },
        computed: {
            isInFavorite(){
                if(this.massagist.inFavorite){
                    return {
                        light: false,
                        dark: true
                    }
                } else {
                    return {
                        light: true,
                        dark: false
                    }
                }
            }
        },
        mounted(){
            this.getMainPhoto();
        }
    }
</script>

<style lang="scss" scoped>
    .app-massagist-main-card{
        width: 20%;
        min-width: 340px;
        height: 500px;
        background-color: $accent;
        border: transparent;
        border-radius: 0.5em;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 2em 1em;
        .card-gallery{
            width: 100%;
            height: 70%;
            overflow: hidden;
            border-top-left-radius: 0.5em;
            border-top-right-radius: 0.5em;
            position: relative;
            cursor: pointer;
            box-shadow: 0px 7px 8px 0px rgba(34, 60, 80, 0.2);
            .main-img{
                width: 100%;
                text-align: center;
                img{
                    max-width: 100%;
                }
                .warning{
                    margin: 1em;
                }
            }
            .sub-gallary{
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 25%;
                background-color: $accent-50;
                display: flex;
                justify-content: space-around;
                align-items: center;
                    .sub-img{
                        max-width: 20%;
                        max-height: 80%;
                        overflow: hidden;
                        img{
                            max-width: 100%;
                        }
                    }
            }
        }
            .card-options{
                position: absolute;
                top: 0;
                left: 0;
                padding: 2em 1em;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .icon{
                    margin: 0.5em 0;
                }
            }
            .card-description{
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 1em 0 0 1em;
                height: 30%;
                .name, .age {
                    color: $white;
                    font-size: 1.2em;
                    font-weight: 700;
                    margin-bottom: 0.5em;
                }
                .location{
                    font-size: 1em;
                    color: black;
                    font-weight: 700;
                     margin-bottom: 0.5em;
                }
                .price{
                    color: $white;
                    font-size: 1.2em;
                    font-weight: 700;
                    margin-bottom: 0.5em;
                }
                .description-like{
                    position: absolute;
                    right: 5%;
                    bottom: 7%;
                }
                .more{
                    color: $white;
                    font-size: 1em;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.3s ease;
                }
            }
    }

    @media screen and(min-width: 1280px){
    .app-massagist-main-card{
            .card-description{          
                .more{                   
                    &:hover{
                        color: $accent-dark;
                    }
                }
            }
    }
    }
</style>