<template>
    <div class="modal-to-get-location" v-if="isOpened">
        <BaseCloseIcon class="icon" @action="$emit('closeModal')" />
        <h2 class="location-question">Ваш город Москва?</h2>
        <div class="actions-block">
            <BaseButton value="да" animated bbt ca @action="$emit('setLocation', 'Москва')" />
            <BaseButton value="выбрать другой" animated bbt ca @action="$emit('chooseOtherLocation')" />
        </div>
  </div>
</template>

<script>
    export default {
        props: {
            isOpened: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-to-get-location{
        width: 350px;
        height: 220px;
        background-color: $primary;
        border-radius: 0.4em;
        border: 1px solid $accent-dark;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .icon{
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
        }
        .actions-block{
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
  }
</style>