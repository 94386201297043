<template>
    <div class="app-layout-public-main-user">
        <header>
            <slot name="header"></slot>
        </header>
        <aside>
            <slot name="aside"></slot>
        </aside>
        <main>
            <slot name="main"></slot>
        </main>
        <section>
            <slot name="section"></slot>
        </section>
        <footer>
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
    .app-layout-public-main-user{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        display: grid;
        grid-template-rows: repeat(4, auto);
        header{
            grid-row: 1 / 2;
        }
        aside{
            display: none;
        }
        main{
            grid-row: 2 / 4;
        }
        section{
            grid-row: 4 / 5;
        }
        footer{
            grid-row: 5 / 6;
        }
    }

    @media screen and(min-width: 1024px) {
    .app-layout-public-main-user{
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 20px;
        header{
            grid-column: 1 / 13;
        }
        aside{
            grid-column: 1 / 4;
            display: block;
        }
        main{
            grid-column: 4 / 13;
        }
        section{
            grid-column: 1 / 13;
        }
        footer{
            grid-column: 1 / 13;
        }
    }
    }
</style>