export default {
    addToFavorites(id, massagists){         
        let favorites = JSON.parse(localStorage.getItem('favorites'));
        if(favorites){
            const isIDinFavorites = favorites.some(item => item === id);
            if(isIDinFavorites){
                favorites.forEach((item, index) => item === id ? favorites.splice(index, 1) : false)
                massagists.forEach(massagist => massagist.id === id ? massagist.inFavorite = 0 : false)
            } else {
                favorites.push(id)
                massagists.forEach(massagist => massagist.id === id ? massagist.inFavorite = 1 : false)
            }
            localStorage.setItem('favorites', JSON.stringify(favorites))
            if(favorites.length < 1){
                localStorage.removeItem('favorites')
            }            
        } else {
            localStorage.setItem('favorites', JSON.stringify([id]))
            massagists.forEach(massagist => massagist.id === id ? massagist.inFavorite = 1 : false)
        }    
    },
    // removeFromFavorites(id){
    //     let favorites = JSON.parse(localStorage.getItem('favorites'));
    //     // favorites.slice
    // }
}