<template>
    <div class="app-modal-to-set-location" v-if="isOpened">
        <div class="wrapper">
            <div class="search">
                <BaseCloseIcon @action="$emit('closeModal')" class="icon" />
                <h2 class="choose-location">Выберите город из списка:</h2>
                <BaseInput 
                    outlined
                    rounded
                    label="Город"
                    validation="autocomplete" 
                    autocomplete
                    width="320"
                    id="location" 
                    :autocompleteData="autocompleteData"
                    :prefiltredData="prefiltredData"
                    @sendData="choosenLocation.setCurrentLocation($event)"
                />
                <BaseButton
                    value="сохранить"
                    animated bbt ca
                    @action="$emit('setLocation', choosenLocation.currentLocation)"
                    :disabled="choosenLocation.isLocationChoosen"
                    class="btn"
                />
            </div>
            <div class="regions">
                <div class="regions-list" v-if="screens.regions">
                    <div class="letter-block" v-for="letter, index in regeonsList" :key="index">
                        <h3 class="letter-title">{{ letter.letter }}</h3>
                        <div class="regions-list" v-for="region, index in letter.regions" :key="index" @click="filtredCitiesByRegion(region)">{{ region.name }}</div>
                    </div>  
                </div>
                <div class="cities-list" v-if="screens.cities">
                    <div class="city" v-for="city, index in currentRegionCitiesList.value" :key="index" @click="choosenLocation.setCurrentLocationDirectly(city)">{{ city }}</div>
                    <div class="back" @click="backToRegionScreen"><BaseBackIcon />назад</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive } from 'vue';
import regions from "@/database/regions.js";
    export default {
        props: {
            isOpened: {
                type: Boolean,
                default: false
            },
            prefiltredData: {
                type: Object,
                default: {}
            },
            autocompleteData: {
                type: Object,
                default: {}
            }
        },
        setup(props, { emit }){
            const choosenLocation = reactive({
                currentLocation: null,
                setCurrentLocation: (data) => choosenLocation.currentLocation = data,
                setCurrentLocationDirectly: (data) => {
                    choosenLocation.currentLocation = data
                    emit('setLocation', choosenLocation.currentLocation)
                    backToRegionScreen()
                },
                isLocationChoosen: computed(() => {
                    if(choosenLocation.currentLocation){
                        return false
                    } else {
                        return true
                    }
                })
            })

            const regeonsList = regions;

            const screens = reactive({
                regions: true,
                cities: false
            })

            const currentRegionCitiesList = reactive([]);

            const filtredCitiesByRegion = (region) => {
                currentRegionCitiesList.value = region.cities;
                screens.regions = false;
                screens.cities = true;
            }

            const backToRegionScreen = () => {
                screens.regions = true;
                screens.cities = false;
            }

            return {
                choosenLocation,
                regeonsList,
                filtredCitiesByRegion,
                screens,
                currentRegionCitiesList,
                backToRegionScreen
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-modal-to-set-location{
        width: 100%;
        min-height: 100%;
        background-color: $primary;
        border-radius: 0.4em;
        border: 1px solid $accent-dark;
        position: absolute;
        top: 0;
        left: 0;        
        z-index: 1000;
        padding: 0 0 3em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
         overflow: scroll;
        .wrapper{
            width: 100%;
            max-width: 1440px;
            margin: 0 auto;
            padding-left: 2em;
            
            .search{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 200px;
                .choose-location{
                    padding: 1em 0;
                }
                .btn{
                    margin: 1em 0;
                }
                .icon{
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    cursor: pointer;
                }
            }
            .regions{
               
                .regions-list{
                    flex-grow: 1;
                    display: flex;
                    flex-wrap: wrap;
                    
                    .letter-block{
                        width: 300px;
                        margin-bottom: 1em;
                        .letter-title{
                            margin-bottom: 1em;
                        }
                        .regions-list{
                            cursor: pointer;
                            &:hover{
                                font-weight: bold;
                                color: $accent-dark;
                            }
                    }
                }
            }
                .cities-list{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    .city{
                        padding: 0.2em 0;
                        &:hover{
                            font-weight: bold;
                            color: $accent-dark;
                            cursor: pointer;
                        }
                    }
                    .back{
                        padding: 2em;
                        font-size: 1.2em;
                        font-weight: bold;
                        color: $accent-dark;
                        cursor: pointer;
                        svg{
                            margin-right: 0.5em;
                        }
                    }
                }

            }
    }
  }
</style>