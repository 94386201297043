<template>
    <div class="app-searching-form">
        <AppSelectInput @selected="$emit('getMassagist', $event)" :select="selects.massagist" class="select"/>
        <AppSelectInput @selected="$emit('getClient', $event)" :select="selects.client" class="select"/>
        <AppSelectInput @selected="$emit('getPlace', $event)" :select="selects.place" class="select"/>
        <!-- <BaseInput 
            outlined
            rounded
            label="Город"
            validation="autocomplete" 
            autocomplete 
            id="location" 
            :autocompleteData="autocompleteData"
            :prefiltredData="prefiltredData"
            @sendData="$emit('getCity', $event)"
            margin="0 1em 1em"
        /> -->
        <BaseCloseIcon class="icon" @action="$emit('clear')" />
        <div class="btn">
            <AppRegularBtn dark text="найти" :loading="loading" @action="$emit('search')" :disabled="disabled" />
        </div>      
    </div>
</template>

<script>
import AppRegularBtn from "@/components/buttons/app-regular-btn.vue";
import AppSelectInput from "@/components/inputs/app-select-input.vue";
import AppBaseInput from "@/components/inputs/app-basic-input.vue"
import SearchingForm from "@/database/searchingForm.js";
// import cities from "@/database/cities.js";
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: true
            }
        },
        components: {
            AppRegularBtn,
            AppSelectInput,
            AppBaseInput
        },
        data() {
            return {
                // autocompleteData: cities,
                prefiltredData: [
                    "Москва",
                    "Санкт-Петербург",
                    "Ектеринбург",
                    "Новосибирск",
                ],    
                selects: SearchingForm.selects,
            }
        },
    }
</script>

<style lang="scss" scoped>
    .app-searching-form{
        width: 95%;
        margin: 0 auto;
        border-radius: 0.3em;
        padding: 2em 0;
        background-color: $accent;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        .icon{
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
        }
        .city-input{
            margin: 0 1em 1em;
            width: 350px;
            height: 53px;
        }
        .select{
            width: 350px;
            margin: 0 1em 1em;
            position: relative;
            box-shadow: 4px 4px 8px 0px rgba(131, 50, 177, 0.2);         
        }
        .btn{
            width: 100%;
            text-align: center;
        }
    }

        @media screen and(min-width: 1024px){
            .app-searching-form{
                width: 100%;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                padding: 2em;
                .select{
                    width: 300px;
                    margin: 0 1em 1em;
                    // width: 25%;     
                }
                 .city-input{
                    margin: 0 1em 1em;
                    width: 300px;
                    height: 53px;
                }
                .btn{
                    width: auto;
                }
    }
}
        @media screen and(min-width: 1190px){
            .app-searching-form{               
                .select{
                    width: 170px;
                    margin: 0 1em 1em;
                    // width: 25%;     
                }
                 .city-input{
                    margin: 0 1em 1em;
                    width: 170px;
                    height: 53px;
                }
                .btn{
                    align-self: flex-start;
                }
    }
}
        @media screen and(min-width: 1440px){
            .app-searching-form{               
                .select{
                    width: 200px;
                    margin: 0 1em 1em;
                    // width: 25%;     
                }
                 .city-input{
                    margin: 0 1em 1em;
                    width: 200px;
                    height: 53px;
                }
                .btn{
                    align-self: flex-start;
                }
    }
}
</style>