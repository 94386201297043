<template>
  <AppLayoutUserPublicMain>
    <template v-slot:header>
      <AppHeader :countFavorites="favoritesAmount.count" />
    </template>
    <template v-slot:aside>
      <div class="main-aside">
        <div class="main-aside-filter">
          <AppCityNavigation @openRegions="goToRegionsPage" />
          <AppModalToGetLocation 
            :isOpened="location.isModalToGetLocationOpened"
            @setLocation="location.setLocation($event)"
            @chooseOtherLocation="goToRegionsPage"
          />
          <AppMainFilter
            @isVarified="mainFilter.isVarified($event)"
            @minAge="mainFilter.minAge($event)"
            @maxAge="mainFilter.maxAge($event)"
            @minPrice="mainFilter.minPrice($event)"
            @maxPrice="mainFilter.maxPrice($event)"
            @status="mainFilter.status($event)"
            @sex="mainFilter.sex($event)"
            :isVerifiedChecked="mainFilter.options.verified.is_active"
            :dropMinAge="!mainFilter.options.age_from.is_active"
            :dropMaxAge="!mainFilter.options.age_till.is_active"
            :dropMinPrice="!mainFilter.options.price_from.is_active"
            :dropMaxPrice="!mainFilter.options.price_till.is_active"
            :dropSaloon="mainFilter.options.status.is_active && mainFilter.options.status.label === 'saloon'"
            :dropMassagist="mainFilter.options.status.is_active && mainFilter.options.status.label === 'massagist'"
            :dropMale="mainFilter.options.sex.is_active && mainFilter.options.sex.label === 'male'"
            :dropFemale="mainFilter.options.sex.is_active && mainFilter.options.sex.label === 'female'"
           />
        </div>
        <div class="main-aside-massage-types">
          <h3 class="title">Виды массажа</h3>
          <ul class="list">
            <li class="list-item" 
                v-for="(massageType, index) in massageTypes"
                :key="index"
                @click="mainFilter.massageType(massageType, 'base')">{{ massageType.label }}
            </li>
          </ul>
        </div>
        <div class="main-aside-add-massage-types">
          <h3 class="title">Дополнительные виды массажа</h3>
          <ul class="list">
            <li class="list-item" 
                v-for="(massageType, index) in additionalMassageTypes"
                :key="index"
                @click="mainFilter.massageType(massageType, 'additional')">{{ massageType.label }}
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-slot:main>
      <div class="title">Массаж в <span class="title-accent">твоем</span> городе</div>
      <div class="mobile-city-management">
        <AppCityNavigation @openRegions="goToRegionsPage" />
      </div>
      <AppSeacringForm
        @getMassagist="searchingData.getMassagist($event)"
        @getClient="searchingData.getClient($event)"
        @getPlace="searchingData.getPlace($event)"
        @getCity="searchingData.getCity($event)"
        @search="searchingData.searchForMassage($event)"
        @clear="searchingData.clearForm"
        :loading="searchingData.searchLoading"
        :disabled="searchingData.isDataValid"
      />
      <div class="filter-options">
        <div class="open-filter-mobile"
             @click="mainFilter.isMobileActive = true">
                <span>Настроить фильтры</span>
                <AppSearchIcon />
        </div>
        <div class="mobile-filter" v-if="mainFilter.isMobileActive">
          <BaseCloseIcon @action="mainFilter.isMobileActive = false" class="icon" />
          <AppMainFilter
            @isVarified="mainFilter.isVarified($event)"
            @minAge="mainFilter.minAge($event)"
            @maxAge="mainFilter.maxAge($event)"
            @minPrice="mainFilter.minPrice($event)"
            @maxPrice="mainFilter.maxPrice($event)"
            @status="mainFilter.status($event)"
            @sex="mainFilter.sex($event)"
            :isVerifiedChecked="mainFilter.options.verified.is_active"
            :dropMinAge="!mainFilter.options.age_from.is_active"
            :dropMaxAge="!mainFilter.options.age_till.is_active"
            :dropMinPrice="!mainFilter.options.price_from.is_active"
            :dropMaxPrice="!mainFilter.options.price_till.is_active"
            :dropSaloon="mainFilter.options.status.is_active && mainFilter.options.status.label === 'saloon'"
            :dropMassagist="mainFilter.options.status.is_active && mainFilter.options.status.label === 'massagist'"
            :dropMale="mainFilter.options.sex.is_active && mainFilter.options.sex.label === 'male'"
            :dropFemale="mainFilter.options.sex.is_active && mainFilter.options.sex.label === 'female'"
           />
           <AppRegularButton text="применить" dark class="btn" @click="mainFilter.isMobileActive = false" />
        </div>
        <div class="filter-option"
              v-for="option, index in mainFilter.activeOptions"
              :key="index">
          <span class="option">{{ option.name }}</span>
          <span class="name">{{ option.value }}</span>
          <BaseCloseIcon class="icon" @click="mainFilter.deliteOption(option.id)" />
        </div>
        <div class="filter-option-dark"
           v-if="mainFilter.activeOptions.length > 0"
           @click="mainFilter.clearFilter">Очистить фильтр</div>
      </div>
      <div class="massagists-cards" v-if="!filtredMassagists.isFilterActive">
        <div class="page"
            :class="{ 'page-active': index + 1 === filtredMassagists.activePage }"
            v-for="page, index in filtredMassagists.pages"
            :key="index"
            @click="filtredMassagists.changePage(index)"
            >{{ index + 1 }}</div>
        <h2 v-if="filtredMassagists.noResults && !filtredMassagists.loading">В вашем регионе нет актуальных анкет</h2>
        <div v-if="!filtredMassagists.noResults && !filtredMassagists.loading" class="massagists-cards">
        <AppMassagistCardMain 
          v-for="(massagist, index) in filtredMassagists.massagistsToShowOnCurrentPage"
          :key="index"
          :massagist="massagist"
          @action="goToPage(massagist.id)"
          @like="addToFavorite($event)" />
          
        </div>
        <div class="massagists-cards" v-if="filtredMassagists.loading">
          <AppMassagistCardMainLoading 
            v-for="(card, index) in 6"
            :key="index"
          />
        </div>
        <div class="page"
            :class="{ 'page-active': index + 1 === filtredMassagists.activePage }"
            v-for="page, index in filtredMassagists.pages"
            :key="index"
            @click="filtredMassagists.changePage(index)"
            >{{ index + 1 }}</div>
      </div>
    </template>
    <template v-slot:section>
      <!-- <AppNewProfiles @action="goToPage($event)" /> -->
    </template>
    <template v-slot:footer>
      <AppNavigationMobile />
      <AppFooter />
    </template>
  </AppLayoutUserPublicMain>
</template>



<script>
import AppLayoutUserPublicMain from "@/layouts/user/public/app-layout-public-main-user.vue";
import AppHeader from "@/sections/app-header.vue";
import AppNavigationMobile from "@/sections/app-navigation-mobile.vue";
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
import AppSeacringForm from "@/components/forms/app-searching-form.vue";
import AppMassagistCardMain from "@/components/cards/app-massagist-main-card.vue";
import AppMassagistCardMainLoading from "@/components/cards/app-massagist-main-card-loading.vue";
import AppCityNavigation from "@/components/navigation/app-city-navigation.vue";
import AppMainFilter from "@/components/filters/app-main-filter.vue";
import AppFooter from "@/sections/app-footer.vue";
import massageTypesList from "@/database/massageInputTypes.js";
import additionalMassageTypesList from "@/database/massageInputTypesAdditional.js";
import AppModalToGetLocation from "@/components/popups/app-modal-to-get-location.vue";
import AppModalToSetLocation from "@/components/popups/app-modal-to-set-location.vue";
import AppSearchIcon from "@/components/icons/app-search-icon.vue";

import cities from "@/database/cities.js";
import getMassagistsByCity from "@/logic/getMassagistsByCity.js";
import filterMassagistsByMassageTypes from "@/logic/filterMassagistsByMassageTypes.js";
// import setMassagistsBetsBalancesAndPhotos from "@/logic/setMassagistsBetsBalancesAndPhotos.js";
import favorites from "@/logic/favorites.js";
import searchFunctions from "@/logic/searchFunctions.js";


import filterFunction from "@/logic/filter.js";

// import massagistAPI from "@/api/massagist.js";

import { ref, onUpdated, onMounted, computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {

  name: "Main",
  components: {
    AppLayoutUserPublicMain,
    AppHeader,
    AppNavigationMobile,
    AppSeacringForm,
    AppMassagistCardMain,
    AppRegularButton,
    AppCityNavigation,
    AppMainFilter,
    AppFooter,
    AppModalToGetLocation,
    AppModalToSetLocation,
    AppMassagistCardMainLoading,
    AppSearchIcon
  },
  setup(){
    const store = useStore();
    const router = useRouter();
    const massageTypes = massageTypesList;
    const additionalMassageTypes = additionalMassageTypesList;
    const searchForMassage = (data) => {
      searchLoading.value = true;
      setTimeout(() => {
        searchLoading.value = false;
      }, 4000)
    };
    const searchLoading = ref(false);

    const favoritesAmount = reactive({
      count: 0
    });

    const mainFilter = reactive({
      isMobileActive: false,
      activeOptions: computed(() => {
        let options = [];

        for(let item in mainFilter.options){
          if(mainFilter.options[item].is_active){
            options.push(mainFilter.options[item])
          }
        }

        return options;
      }),
      deliteOption: (id) => {
        for(let item in mainFilter.options){
          if(mainFilter.options[item].id === id){
            mainFilter.options[item].is_active = false;
          }
        }
        mainFilter.filter();
      },
      options: {
        verified: {
            is_active: false,
            name: "Только верифицированные анкеты",
            label: "",
            value: "",
            id: 1
        },
        status: {
            is_active: false,
            name: "Вид специалиста:",
            label: "",
            value: "",
            id: 2
        },
        sex: {
            is_active: false,
            name: "Пол массажиста:",
            label: "",
            value: "",
            id: 3
        },
        massage_type: {
            is_active: false,
            name: "Тип массажа:",
            label: "",
            value: "",
            id: 4
        },
        age_from: {
            is_active: false,
            name: "Возраст от:",
            label: null,
            value: "",
            id: 5
        },
        age_till: {
            is_active: false,
            name: "Возраст до:",
            label: null,
            value: "",
            id: 6
        },
        price_from: {
            is_active: false,
            name: "Цена за час от:",
            label: null,
            value: "",
            id: 7
        },
        price_till: {
            is_active: false,
            name: "Цена за час до:",
            label: null,
            value: "",
            id: 8
        },
        massage_type: {
          is_active: false,
          name: "Вид массажа:",
          label: null,
          value: "",
          id: 9
        },
        additional_massage_type: {
          is_active: false,
          name: "Дополнительно:",
          label: null,
          value: "",
          id: 10
        }
      },
      filter: () => {
        filtredMassagists.filterMassagistsByBets();
        if(mainFilter.options.status.is_active){
          filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
            if(item.status === mainFilter.options.status.label){
              return item;
            }
          })
        }
        if(mainFilter.options.sex.is_active){
          filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
            if(item.sex === mainFilter.options.sex.label){
              return item;
            }
          })
        }
         if(mainFilter.options.verified.is_active){
            filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
                if(item.verified.is_verified){
                    return item;
                }
            })
        }
        if(mainFilter.options.age_from.is_active){
          filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
            if(+item.age >= +mainFilter.options.age_from.label){
              return item;
            }
          })
        }
        if(mainFilter.options.age_till.is_active){
           filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
            if(+item.age <= +mainFilter.options.age_till.label){
              return item;
            }
          })
        }
        if(mainFilter.options.price_from.is_active){
           filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
             if(item.my_place_per_hour_price){
               if(+item.my_place_per_hour_price >= +mainFilter.options.price_from.label){
                 return item;
               }
             }
          })
        }
        if(mainFilter.options.price_till.is_active){
           filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
             if(item.your_place_per_two_hours_price){
               if(+item.your_place_per_two_hours_price <= +mainFilter.options.price_till.label){
                 return item;
               }
             }
          })
        }
        if(mainFilter.options.massage_type.is_active){
          filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
            let regexp = new RegExp(`${mainFilter.options.massage_type.label}`, "gi");
            if(item.base_massage_types){
              if(item.base_massage_types.match(regexp)){
                    return item;
                }
            }             
          })
        }
        if(mainFilter.options.additional_massage_type.is_active){
          filtredMassagists.massagists = filtredMassagists.massagists.filter(item => {
            let regexp = new RegExp(`${mainFilter.options.additional_massage_type.label}`, "gi");
            if(item.base_massage_types){
              if(item.additional_massage_types.match(regexp)){
                    return item;
                }
            }             
          })
        }
        filtredMassagists.changePage(0);
      },
      checkFilter: () => {
        let check = [];
        for(let item in mainFilter.options){
          if(mainFilter.options[item].is_active){
            check.push(mainFilter.options[item])
          } else {
            check.push(false)
          }
        }
        check = check.some(item => item === true)
        if(check){
          mainFilter.options.verified.is_active = true;
          mainFilter.filter();
        } else {
          mainFilter.clearFilter();
        }
      },
      clearFilter: () => {
        filtredMassagists.filterMassagistsByBets();
        for(let item in mainFilter.options){
         mainFilter.options[item].is_active = false
        }
      },
      isVarified: (data) => {
        if(data.checked){
          mainFilter.options.verified.is_active = true;
          mainFilter.filter();
        } else {
          mainFilter.options.verified.is_active = false;
          mainFilter.filter();
        }
      },
      minAge: (data) => {
        mainFilter.options.age_from.is_active = true;
        mainFilter.options.age_from.label = data;
        mainFilter.options.age_from.value = data;
        mainFilter.filter();
      },
      maxAge: (data) => {
        mainFilter.options.age_till.is_active = true;
        mainFilter.options.age_till.label = data;
        mainFilter.options.age_till.value = data;
        mainFilter.filter();
      },
      status: (data) => {
        mainFilter.options.status.is_active = true;
        mainFilter.options.status.label = data;
        if(data === 'saloon'){
          mainFilter.options.sex.is_active = false;
          mainFilter.options.status.value = 'Салон';
        } else {
          mainFilter.options.status.value = 'Частное лицо';
        }
        mainFilter.filter();        
      },
      sex: (data) => {
        mainFilter.options.sex.is_active = true;
        mainFilter.options.sex.label = data;
        if(data === 'male'){
          mainFilter.options.sex.value = "Мужчина"
        } else {
          mainFilter.options.sex.value = "Женщина"
        }
        mainFilter.filter();        
      },
      minPrice: (data) => {
        mainFilter.options.price_from.is_active = true;
        mainFilter.options.price_from.label = data;
        mainFilter.options.price_from.value = data;
        mainFilter.filter();
      },
      maxPrice: (data) => {
        mainFilter.options.price_till.is_active = true;
        mainFilter.options.price_till.label = data;
        mainFilter.options.price_till.value = data;
        mainFilter.filter();
      },
      massageType: (data, type) => {
        if(type === 'base'){
          mainFilter.options.massage_type.is_active = true;
          mainFilter.options.massage_type.label = data.name;
          mainFilter.options.massage_type.value = data.label;
          mainFilter.filter();
        } else if(type === 'additional'){
          mainFilter.options.additional_massage_type.is_active = true;
          mainFilter.options.additional_massage_type.label = data.name;
          mainFilter.options.additional_massage_type.value = data.label;
          mainFilter.filter();
        }
      }
    })

    const addToFavorite = (id) => {
      favorites.addToFavorites(id, filtredMassagists.massagists);
      countFavorites();
    }

    const goToRegionsPage = () => {
      router.push('/regions')
    }

    const searchingData = reactive({
      massagist: null,
      client: null,
      place: null,
      city: store.state.location,
      searchLoading: false,
      isDataValid: computed(() => {
        let state = true;
        searchingData.massagist &&
        searchingData.client &&
        searchingData.place &&
        searchingData.city ? state = false : state = true

        return state;
      }),
      getMassagist: (data) => searchingData.massagist = data,
      getClient: (data) => searchingData.client = data,
      getPlace: (data) => searchingData.place = data,
      getCity: (data) => {
        if(data.length > 1){
          searchingData.city = data;
        } else {
          searchingData.city = null;
        }
      },
      searchForMassage: async () => {
        searchingData.searchLoading = true;
        filtredMassagists.loading = true;
        let massagists = await searchFunctions.searchMassagistByOptions({
          sex: searchingData.massagist,
          client: searchingData.client,
          place: searchingData.place,
          city: searchingData.city
        });

        if(massagists.error){
          searchingData.searchLoading = false;
          filtredMassagists.noResults = true;
          filtredMassagists.loading = false;
        } else {
          searchingData.searchLoading = false;
          store.dispatch('setMassagists', massagists)
  
          filtredMassagists.filterMassagistsByBets();
        }

      },
      clearForm: async () => {
        searchingData.massagist = null;
        searchingData.client = null;
        searchingData.place = null;
        searchingData.city = null;
        searchingData.searchLoading = false;
        await location.getLocation().catch(err => console.log(err))
      } 
    })

    const filtredMassagists = reactive({
      noResults: false,
      pages: computed(() => {
        let a = filtredMassagists.massagists.length / filtredMassagists.massagistsOnPage;
        return Math.ceil(a)
      }),
      massagistsOnPage: 6,
      massagistsToShowOnCurrentPage: [],
      activePage: 1,
      massagists: [],
      filtredMassagists: [],
      isFilterActive: false,
      filterOptions: [],
      loading: true,
      changePage: (index) => {
        filtredMassagists.massagistsToShowOnCurrentPage = [];

        filtredMassagists.activePage = index + 1;
        
        let start = (filtredMassagists.activePage - 1) * filtredMassagists.massagistsOnPage;
        for(start; start <= (filtredMassagists.massagistsOnPage * filtredMassagists.activePage) - 1; start++){
            if(start <= filtredMassagists.massagists.length - 1){
              filtredMassagists.massagistsToShowOnCurrentPage.push(filtredMassagists.massagists[start])
            }
        }
      },
      filterMassagistsByBets: () => {
        let allMassagists = store.state.massagists;
        console.log(allMassagists)
        if(allMassagists === undefined){
          getMassagists(location.location)
        } else {
           let sortedByBets = allMassagists.sort((a, b) => a.bet.bet - b.bet.bet);
          sortedByBets = sortedByBets.reverse();
          filtredMassagists.massagists = sortedByBets;
          filtredMassagists.massagists = filtredMassagists.massagists.filter(massagist => {
            if(massagist.balance.balance + massagist.balance.bonus > 0){
              return massagist;
            }
          })
          filtredMassagists.changePage(0);
          filtredMassagists.loading = false;
        }
      },
      filterCurrentMassagistsByMassageTypes: (massageType, type) => {
        filtredMassagists.massagists = [];
        filtredMassagists.filterOptions = [];
        let allMassagists = store.state.massagists;
        filtredMassagists.filterOptions.push({ option: 'Тип массажа', name: massageType.label });
        filtredMassagists.massagists = filterMassagistsByMassageTypes(massageType.id, allMassagists, type);
        filtredMassagists.changePage(0);
      },
      clearMassageTypesFilter: () => {
        filtredMassagists.filterOptions = [];
        filtredMassagists.filterMassagistsByBets();
      }
    })

    const countFavorites = () => {
      let count;
      const favorites = JSON.parse(localStorage.getItem('favorites'));
      favorites ? count = favorites.length : count = 0;

      favoritesAmount.count = count;
    }
                
            

    const isUserLoggedIn = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if(user){
        store.dispatch('setUser', user)
        return true;
      } else {
        return false;
      }
    }

    const getMassagists = async (city) => {
      filtredMassagists.loading = true;
      const massagists = await getMassagistsByCity(city).then(response => {
        if(response.error){
          return false;
        } else {
          return response;
        }
      });
      if(massagists){
        const isInFavorites = JSON.parse(localStorage.getItem('favorites'))
        if(isInFavorites){
          massagists.forEach(massagist => {
            isInFavorites.forEach(id => massagist.id === id ? massagist.inFavorite = 1 : false)
          })
        }   
        store.dispatch('setMassagists', massagists)
  
        filtredMassagists.filterMassagistsByBets();
        filtredMassagists.loading = false;
      } else {
        filtredMassagists.noResults = true;
        filtredMassagists.loading = false;
      }
      
    }

    const location = reactive({
      isModalToGetLocationOpened: false,
      isModalToSetLocationOpened: computed(() => {
        if(store.state.isModalToSetLocationOpened){
          return true
        } else {
          return false
        }
      }),
      location: computed(() => store.state.location),
      locations: cities,
      getLocation: async () => {
        if(isUserLoggedIn()){
          return false;
        } else {
          console.log('getting location')
          const currentlocation = JSON.parse(localStorage.getItem('ermUserLocation'));
          console.log(currentlocation)
          if(currentlocation){
            store.dispatch('setLocation', currentlocation)
            await getMassagists(location.location)
          } else {
            location.isModalToGetLocationOpened = true;
          }          
        }
      },
      setLocation: (data) => {
        store.dispatch('setLocation', data);
        localStorage.setItem('ermUserLocation', JSON.stringify(data))
        location.closeModalToGetLocation();
        location.closeModalToSetLocation();
        getMassagists(location.location)
      },
      closeModalToGetLocation: () => {
        location.isModalToGetLocationOpened = false;
        const body = document.querySelector('.body');
        body.classList.remove('body-closed')
        getMassagists(location.location)
      },
      openModalToSetLocation: () => {
        location.isModalToGetLocationOpened = false;
        store.dispatch('openModalToSetLocation');
      },
      closeModalToSetLocation: () => {
        store.dispatch('closeModalToSetLocation')
        const body = document.querySelector('.body');
        body.classList.remove('body-closed')
        getMassagists(location.location)
      }
    })

    const goToPage = (id) => {
      router.push(`/massagist/${id}`)
    }


    onMounted(location.getLocation, countFavorites());
    onUpdated(filtredMassagists.filterMassagistsByBets);
    
    return {
      searchForMassage,
      searchLoading,
      filtredMassagists,
      massageTypes,
      additionalMassageTypes,
      goToPage,
      location,
      addToFavorite,
      favoritesAmount,
      searchingData,
      goToRegionsPage,
      mainFilter
    }
  }
};
</script>

<style lang="scss" scoped>
  .title{
    font-size: 1.5em;
    margin: 1em auto;
    text-align: center;
    color: $black;
    font-weight: 700;
    margin-top: -0.5em;
    .title-accent{
      color: $accent-dark;
    }
  }

  .mobile-city-management{
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .mobile-city-management{
      width: 100%;
      padding: 2em 0;
      display: flex;
      justify-content: center;
    }
  }



  .filter-options{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 1em;
    .open-filter-mobile{
      display: none;
    }
    .filter-option-dark{
        background-color: $accent-dark;
        padding: 0.7em;
        color: white;
        border: transparent;
        border-radius: 20px;
        width: auto;
        margin-right: 1em;
        margin-bottom: 1em;
        cursor: pointer;
      }
    .filter-option{
      padding: 0.7em;
      background-color: $accent;
      color: white;
      border: transparent;
      border-radius: 20px;
      width: auto;
      display: flex;
      align-items: center;
      margin-right: 1em;
      margin-bottom: 1em;
      
      .option{
        margin-right: 0.5em;
      }
      .name{
        margin-right: 0.5em;
      }
      .icon{
        cursor: pointer;
      }
      
    }
  }

  .main-aside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-aside-filter{
      height: 700px;
    }
    .main-aside-massage-types{
      padding: 1em 0;
      margin: 0 0 1em 0;
      text-align: left;
      width: 70%;
      .list{
        list-style: none;
        .list-item{
          font-size: 1.1em;
          font-weight: 700;
          color: $black;
          cursor: pointer;
          &:hover{
            color: $accent-dark;
          }
        }
      }

    }
    .main-aside-add-massage-types{
      padding: 1em 0;
      margin: 0 0 1em 0;
      text-align: left;
      width: 70%;
          .list{
        list-style: none;
        .list-item{
          font-size: 1.1em;
          font-weight: 700;
          color: $black;
          cursor: pointer;
          &:hover{
            color: $accent-dark;
          }
        }
      }
    }
  }

  .massagists-cards{
    width: 100%;
    padding: 2em 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .page{
      padding: 1em;
      font-size: 1.1em;
      border-radius: 0.3em;
      border: 1px solid $accent;
      margin: 0 1em;
      cursor: pointer;
    }
    .page-active{
      background-color: $accent;
      color: $white;
    }
  }



  @media screen and(min-width: 375px){
    .massagists-cards{
      justify-content: center;
  }
  }

    @media screen and (max-width: 1024px) {
    .filter-options{
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .open-filter-mobile{
        display: flex;
        align-items: center;
        background-color: $accent-dark;
        color: $white;
        padding: 0.7em 1em;
        border-radius: 10px;
        margin-bottom: 20px;
        span{
          margin-right: 10px;
          
        }
        
      }
      .mobile-filter{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $primary;
        z-index: 1000;
        .icon{
          position: absolute;
          top: 25px;
          right: 25px;
        }
        .btn{
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
</style>
