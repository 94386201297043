
export default function(massageType, massagists, type){
    if(type === 'base'){
        let filtredMassagists = massagists.filter(massagist => {
            let regexp = new RegExp(`${massageType}`, "gi");
            if(massagist.base_massage_types){
                if(massagist.base_massage_types.match(regexp)){
                    return massagist;
                }
            }
        })

        return filtredMassagists;

    }
    if(type === 'additional'){
        let filtredMassagists = massagists.filter(massagist => {
            let regexp = new RegExp(`${massageType}`, "gi");
            if(massagist.additional_massage_types){
                if(massagist.additional_massage_types.match(regexp)){
                    return massagist;
                }
            }
        })

        return filtredMassagists;
    }
}